<template>
    <div style="background: #f5f5f5">
        <div style="width: 980px;border-radius: 8px; overflow: hidden; position: relative;min-height: 600px;background: #fff;" id="parent">
        <div class="right_content_parent">
            <div class="right_content">
                <div style="height: auto;padding-bottom: 20px; width: 836px;margin:0 auto;">
                    <div>
                        <div style="font-size: 20px;color: #333;text-align: center">国家高新技术企业认定评测系统</div>
                        <div style="font-size: 14px;color: #FFA004;margin: 12px 0 30px;text-align: center">（为保证评测结果精准，请详细填写并保证数据真实有效）</div>
                        <Steps :current="current">
                            <Step title="企业基本信息"></Step>
                            <Step title="知识产权统计"></Step>
                            <Step title="财务及研发管理统计"></Step>
                            <Step title="提交信息"></Step>
                        </Steps>
                    </div>
                    <div v-show="current == 0">
                        <div style="display: grid;grid-template-columns:40% 60%;margin-top: 32px;">
                            <div class="table_div" >企业统一信用代码：</div>
                            <div class="table_div"><Input v-model="baseForm.creditCode" /></div>
                            <div class="table_div" >企业注册时间：</div>
                            <div class="table_div">
                                <DatePicker placeholder="企业申请认定时须注册成立一年以上"
                                            :transfer="true" type="date" v-model="baseForm.registerTime"></DatePicker>
                            </div>
                            <div class="table_div" >企业所在地：</div>
                            <div class="table_div"><Cascader :data="selectcitydata" :load-data="onLoadCity" v-model="enterpriseAddress" trigger="hover" @on-change="getCity"></Cascader></div>
                            <div class="table_div" >详细地址：</div>
                            <div class="table_div"><Input v-model="baseForm.detailAddress" /></div>
                            <div class="table_div" >企业联系人：</div>
                            <div class="table_div"><Input v-model="baseForm.contactUser" /></div>
                            <div class="table_div" >联系人电话：</div>
                            <div class="table_div"><Input v-model="baseForm.contactTel" /></div>
                            <div class="table_div" >职工总数/科技人员总数：</div>
                            <div class="table_div">
                                <Input class="input-two" v-model="baseForm.researcherNumber" />
                                <div style="width: 40px;text-align: center">/</div>
                                <Input class="input-two" v-model="baseForm.staffTotal" />
                            </div>
                            <div class="table_div" >执政处罚情况：</div>
                            <div class="table_div ">
                                <RadioGroup v-model="baseForm.punishment">
                                    <Radio label="1"><span>有</span></Radio>
                                    <Radio label="0"><span>无</span></Radio>
                                </RadioGroup>
                            </div>
                            <div class="table_div" >主要产品技术领域：</div>
                            <div class="table_div">
                                <Select v-model="baseForm.mptCode" :transfer="transfer">
                                    <Option v-for="(item, index) in technologyList" :key="index" :value="item.value">{{ item.name }}
                                    </Option>
                                </Select>
                            </div>
                            <div class="table_div" >产品获奖情况：</div>
                            <div class="table_div">
                                <Select v-model="baseForm.awards" :transfer="transfer">
                                    <Option value="1">重点新产品</Option>
                                    <Option value="2">高新技术产品</Option>
                                    <Option value="3">名牌产品</Option>
                                    <Option value="3">其他</Option>
                                </Select>
                            </div>
                        </div>

                        <div class="button_style" @click="firstNext()" >
                            下一步
                        </div>
                    </div>
                    <div v-show="current == 1">
                        <div class="step2-notice"><Icon type="ios-information-circle" color="#1890FF" size="16" /></Icon>
                            部分企业信息会自动带出，如信息有误请进行修改操作！
                            <Icon type="ios-close-empty"></Icon></div>
                        <div style="display: grid;grid-template-columns:251px 190px 190px 200px;background: #CCD5DB;grid-gap:1px;border: 1px solid #CCD5DB">
                            <div class="step2-item" style="font-size: 16px;font-weight: bold">知识产权名称</div>
                            <div class="step2-item" style="font-size: 16px;font-weight: bold">自主研发获取</div>
                            <div class="step2-item" style="font-size: 16px;font-weight: bold">受让、受赠、并购获取</div>
                            <div class="step2-item" style="font-size: 16px;font-weight: bold">合计</div>
                            <div :class="['step2-item',{'step2-item-bg':index%2==0}]" :key="'a'+index" v-for="(item,index) in ipList"
                                 :style="{'grid-column': '1 / 2' ,'grid-row': (index+2)+' /'+ (index+3)}">{{step2Data[item.ipItemCode]}}</div>
                            <div :class="['step2-item',{'step2-item-bg':index%2==0}]" :key="'b'+index" v-for="(item,index) in ipList"
                                 :style="{'grid-column': '2 / 3' ,'grid-row': (index+2)+' /'+ (index+3)}">
                                <Input class="step2-input" v-model="item.selfDevNumber"/>项</div>
                            <div :class="['step2-item',{'step2-item-bg':index%2==0}]" :key="'c'+index" v-for="(item,index) in ipList"
                                 :style="{'grid-column': '3 / 4' ,'grid-row': (index+2)+' /'+ (index+3)}">
                                <Input class="step2-input" v-model="item.traGiftMergerNumber"/>项</div>
                            <div style="grid-column: 4 / 5; grid-row: 2 / 8;background: #fff;vertical-align: middle;text-align: center">
                                    <div class="step2-btn step2-btn1">I类 知识产权</div>
                                    <div style="margin: 8px 0;">总数量：{{iplist1_num}}</div>
                                    <div>自主申请数量：{{iplist1_z_num}}</div>
                            </div>
                            <div style="grid-column: 4 / 5; grid-row: 8 / 10;background: #fff;vertical-align: middle;text-align: center">
                                <div class="step2-btn step2-btn2">II类 知识产权</div>
                                <div style="margin: 8px 0;">总数量：{{iplist2_num}}</div>
                                <div>自主申请数量：{{iplist2_z_num}}</div>
                            </div>
                        </div>
                        <div style="display: grid;grid-template-columns:443px 390px;background: #CCD5DB;grid-gap:1px;border: 1px solid #CCD5DB;margin-top: 20px;">
                            <div class="step2-item" >与主要产品或服务相关的知识产权数量</div>
                            <div class="step2-item" ><Input class="step2-input" v-model="relatedNumber"></Input>项</div>
                            <div class="step2-item step2-item-bg" >近三年知识产权总量（含已授权和申请中））</div>
                            <div class="step2-item step2-item-bg" ><Input class="step2-input" v-model="threeYearIpTotal"></Input>项</div>
                            <div class="step2-item" >是否参与编制国家标准、行业标准、检测方法、技术规范</div>
                            <div class="step2-item" ><RadioGroup v-model="orgParticipation">
                                <Radio label="1"><span>有</span></Radio>
                                <Radio label="0"><span>无</span></Radio>
                            </RadioGroup></div>
                        </div>
                        <div style="margin-top: 32px" class="next_style common_liucheng;">
                            <div style="margin:0;border: 1px solid #666;background: #fff;color: #666;" class="button_style" @click="current = 0">
                                上一步
                            </div>
                            <div style="margin:0 0 0 20px" class="button_style" @click="secondNext()">
                                下一步
                            </div>
                        </div>
                    </div>
                    <div v-show="current == 2">
                        <Row style="margin-top: 32px">
                            <Col span="6" class-name="step3-item step3-item-bg" style="font-weight: bold;font-size: 16px;">年度财务状况</Col>
                            <Col span="6" class-name="step3-item step3-item-bg" style="font-weight: bold;font-size: 16px;">年度销售收入</Col>
                            <Col span="6" class-name="step3-item step3-item-bg" style="font-weight: bold;font-size: 16px;">净资产总额</Col>
                            <Col span="6" class-name="step3-item step3-item-bg" style="font-weight: bold;font-size: 16px;">年度投入总额</Col>
                            <Col span="6" class-name="step3-item">{{ this.thisYear - 3 }}年财务状况</Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last1stYearSaleRevenue"/></Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last1stYearNetAssetsTotal"/></Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last1stYearRdInvestmentTotal"/></Col>

                            <Col span="6" class-name="step3-item step3-item-bg">{{ this.thisYear - 2 }}年财务状况</Col>
                            <Col span="6" class-name="step3-item step3-item-bg"><Input class="step3-input" v-model="financeFrom.last2ndYearSaleRevenue"/></Col>
                            <Col span="6" class-name="step3-item step3-item-bg"><Input class="step3-input" v-model="financeFrom.last2ndYearNetAssetsTotal"/></Col>
                            <Col span="6" class-name="step3-item step3-item-bg"><Input class="step3-input" v-model="financeFrom.last2ndYearRdInvestmentTotal"/></Col>

                            <Col span="6" class-name="step3-item">{{ this.thisYear - 1 }}年财务状况</Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last3rdYearSaleRevenue"/></Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last3rdYearNetAssetsTotal"/></Col>
                            <Col span="6" class-name="step3-item"><Input class="step3-input" v-model="financeFrom.last3rdYearRdInvestmentTotal"/></Col>
                        </Row>
                        <Row  style="margin-top: 20px">
                            <Col span="12"  class-name="step3-item step3-item-bg">前三年度累计研发占比</Col>
                            <Col span="12"  class-name="step3-item step3-item-bg">
                                <Select v-model="financeFrom.lastYearHighTechRate" style="width: 96%!important" :transfer="transfer">
                                    <Option value="A">累计销售收入≤5000万，研发累计≥5%</Option>
                                    <Option value="B">5000万＜累计销售收入≤2亿，研发费用累计≥4%</Option>
                                    <Option value="C">累计销售收入＞2亿，研发费用累计≥3%</Option>
                                    <Option value="D">其他</Option>
                                </Select>
                            </Col>
                            <Col span="12"  class-name="step3-item">前三年度累计境内研发费用占总研发费用比</Col>
                            <Col span="12" class-name="step3-item">
                                <Select v-model="financeFrom.threeYearDomesticRdRate" style="width: 96% !important" :transfer="transfer">
                                    <Option value="A"> >60%</Option>
                                    <Option value="B"> <60% </Option>
                                </Select>
                            </Col>
                            <Col span="12"  class-name="step3-item step3-item-bg">上一年度高新技术产品（服务）收入占比</Col>
                            <Col span="12"  class-name="step3-item step3-item-bg">
                                <Select v-model="financeFrom.threeYearRdRate" style="width: 96% !important" :transfer="transfer">
                                    <Option value="A"> >60%</Option>
                                    <Option value="B"> <60%</Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row  style="margin-top: 20px">
                            <Col span="6" class-name="step3-item" style="height: 101px;line-height: 101px">研发管理制度建设情况</Col>
                            <Col span="18" class-name="step3-item" style="height: 101px;text-align: left;">
                                <CheckboxGroup v-model="fourthForm.rdManage" @on-change="delAll('rdManage')">
                                    <Checkbox label="A" class="step3-item-checkbox">已制定企业研究开发的组织管理制度</Checkbox>
                                    <Checkbox label="B" class="step3-item-checkbox">已经建立了研发投入核算体系</Checkbox>
                                    <Checkbox label="C" class="step3-item-checkbox">已经编制了研发费用辅助账</Checkbox>
                                    <Checkbox label="D" class="step3-item-checkbox">以上都没有</Checkbox>
                                </CheckboxGroup>
                            </Col>
                            <Col span="6" class-name="step3-item step3-item-bg" style="height: 101px;line-height: 101px">年度财务状况</Col>
                            <Col span="18" class-name="step3-item step3-item-bg" style="height: 101px;text-align: left;">
                                <CheckboxGroup v-model="fourthForm.rdCooperation" @on-change="delAll('rdCooperation')">
                                    <Checkbox label="B" class="step3-item-checkbox" style="width: auto">与高校/科研院所开展产学研合作（具备签署技术开发协议并已支付委托经费）</Checkbox>
                                    <Checkbox label="A" class="step3-item-checkbox" >已自主设立研发机构</Checkbox>
                                    <Checkbox label="C" class="step3-item-checkbox" >以上都没有</Checkbox>
                                </CheckboxGroup>
                            </Col>

                            <Col span="6" class-name="step3-item" style="height: 101px;line-height: 101px">成果转化激励及创新情况</Col>
                            <Col span="18" class-name="step3-item" style="height: 101px;text-align: left;">
                                <CheckboxGroup v-model="fourthForm.excitationInnovate" @on-change="delAll('excitationInnovate')">
                                        <Checkbox label="A" class="step3-item-checkbox" style="width: auto">已建立科技成果转化的组织实施与激励奖励制度</Checkbox>
                                        <Checkbox label="B" class="step3-item-checkbox">已具备开放式的创新创业平台</Checkbox>
                                        <Checkbox label="C" class="step3-item-checkbox">以上都没有</Checkbox>
                                </CheckboxGroup>
                            </Col>

                            <Col span="6" class-name="step3-item step3-item-bg" style="height: 101px;line-height: 101px">人才培养及激励方法情况</Col>
                            <Col span="18" class-name="step3-item step3-item-bg" style="height: 101px;text-align: left;">
                                <CheckboxGroup v-model="fourthForm.trainingIncentive" @on-change="delAll('trainingIncentive')">
                                            <Checkbox label="A" class="step3-item-checkbox" style="width: auto">已具备科技人员的培养进修、职工技能培训、优秀人才引进制度</Checkbox>
                                            <Checkbox label="B" class="step3-item-checkbox">已具备科技人员评价奖励制度</Checkbox>
                                            <Checkbox label="C" class="step3-item-checkbox">以上都没有</Checkbox>
                                </CheckboxGroup>
                            </Col>
                        </Row>

                        <div style="margin-top: 32px" class="next_style common_liucheng;">
                            <div style="margin:0;border: 1px solid #666;background: #fff;color: #666;" class="button_style" @click="current = 1">
                                上一步
                            </div>
                            <div style="margin:0 0 0 20px" class="button_style" @click="confirmRdManageBt()">
                                提交
                            </div>
                        </div>
                    </div>
                    <div v-show="current == 3">
                        <div style="margin: 110px auto 0;text-align: center">
                            <Icon size="72" color="#52C41A" type="ios-checkmark-circle" />
                            <div class="step4-message-title">信息提交成功</div>
                            <div class="step4-message-sub">您可立即查看您的企业体检报告</div>
                            <div style="margin-top: 60px" class="next_style common_liucheng;">
                                <div style="margin:0;border: 1px solid #666;background: #fff;color: #666;" class="button_style" @click="current = 0">
                                    重新体检
                                </div>
                                <div style="margin:0 0 0 20px" class="button_style" @click="checkReport()">
                                    查看报告
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import $ from "jquery";
    import {
        getTechnologyField,
        confirmBase,
        confirmIp,
        confirmFinance,
        confirmRdManage,
        getReport,
        getEnterpriseInfo,
        getNewProvince,
        getByParentCode,
        getEvalId,
        getBaseInfo,
        getSecondInfo,
        getThirdInfo,
        getFourthInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        isCorrectPassword,
        isPhoneNumber,
        successToast,
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";


    export default {
        name: "EnterprisePhysicalExaminationList",
        mounted() {
            this.getInit()

        },
        data() {
            return {
                oldEvalId: "",
                cityData: {},
                provinceData: {"": ''},
                thisYear: "",
                evalId: "",
                transfer: true,
                secondFlag: false,
                thirdFlag: false,
                selectcitydata:[],
                enterpriseAddress:[],
                // 第一步from
                baseForm: {
                    awards: "",
                    contactTel: "",
                    contactUser: "",
                    creditCode: "",
                    detailAddress: "",
                    mptCode: "",
                    punishment: "0",
                    registerTime: "",
                    researcherNumber: "",
                    staffTotal: "",
                },

                // 第二步form
                ipList:[
                    {
                        ipClass: 1,
                        ipItemCode: "FMZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "ZWXPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "NZWPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "GJXY",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "BHPZ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 1,
                        ipItemCode: "SJZYQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 2,
                        ipItemCode: "SYXXZL",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                    {
                        ipClass: 2,
                        ipItemCode: "RJZZQ",
                        selfDevNumber: 0,
                        traGiftMergerNumber: 0,
                    },
                ],
                step2Data:{
                  'FMZL':'发明专利（Ⅰ类）',
                  'ZWXPZ':'植物新品种（Ⅰ类）',
                  'NZWPZ':'国家农作物品种（Ⅰ类）',
                  'GJXY':'国家新药（Ⅰ类）',
                  'BHPZ':'国际一级中药保护品种（Ⅰ类）',
                  'SJZYQ':'集成电路布局设计专有权（Ⅰ类）',
                  'SYXXZL':'采用新型专利（II类）',
                  'RJZZQ':'软件著作权（II类）',
                },
                orgParticipation: 1,
                threeYearIpTotal: 0,
                relatedNumber: 0,
                data3: [],
                reportList: [], //报告内容
                //第三步form
                financeFrom: {
                    evalId: "",
                    last1stYearNetAssetsTotal: 0,
                    last1stYearRdInvestmentTotal: 0,
                    last1stYearSaleRevenue: 0,
                    last2ndYearNetAssetsTotal: 0,
                    last2ndYearRdInvestmentTotal: 0,
                    last2ndYearSaleRevenue: 0,
                    last3rdYearNetAssetsTotal: 0,
                    last3rdYearRdInvestmentTotal: 0,
                    last3rdYearSaleRevenue: 0,
                    lastYearHighTechRate: "A",
                    threeYearDomesticRdRate: "B",
                    threeYearRdRate: "A",
                },

                // 四form
                fourthForm: {
                    evalId: "22",
                    excitationInnovate: [],
                    rdCooperation: [],
                    rdManage: [],
                    trainingIncentive: [],
                },
                technologyList: [],
                has: "有",
                nohas: false,
                hjqk: "",
                jsly: "",
                sciencePeople: [],
                current: 0,
                tab_height: 0,
                isChoose: 0,
                contentIndex: 0,
                data2: [],
                keyword: "",
                IP_ITEM_List: [],
                BASE_INF_List: [],
                data1: [],
            };
        },
        computed:{
            iplist1_num(){
                let ipList1 = this.ipList.slice(0, 6)
                return ipList1.reduce((prev, cur) => {
                    return prev + parseInt(cur.selfDevNumber) + parseInt(cur.traGiftMergerNumber)},0);
            },
            iplist1_z_num(){
                let ipList1 = this.ipList.slice(0, 6)
                return ipList1.reduce((n,m) =>{return n + parseInt(m.selfDevNumber)},0);
            },
            iplist2_num(){
                let ipList2 = this.ipList.slice(6, this.ipList.length);
                return ipList2.reduce((n,m) =>{return n + parseInt(m.selfDevNumber) + parseInt(m.traGiftMergerNumber)},0);
            },
            iplist2_z_num(){
                let ipList2 = this.ipList.slice(6, this.ipList.length);
                return ipList2.reduce((n,m) =>{return n + parseInt(m.selfDevNumber) },0);
            },
        },
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to) {
                    console.log('to', to)
                    if (JSON.stringify(to.query) !== '{}') {
                        if(to.query.evalId){
                            this.oldEvalId = to.query.evalId
                        }
                    }
                },
            },

        },
        methods: {

            delAll(name) {
                if (name != 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("C") > -1) {
                        this.fourthForm[name] = ['C']
                    }
                } else if (name == 'rdManage') {
                    if (this.fourthForm[name].length >= 2 && this.fourthForm[name].indexOf("D") > -1) {
                        this.fourthForm[name] = ['D']
                    }

                }

            },

            async getInit() {
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.baseForm.contactTel = res.data.contactTel;
                        this.baseForm.staffTotal = res.data.staffTotal;
                        this.baseForm.detailAddress = res.data.site;
                        this.baseForm.registerTime = res.data.registerTime;
                        this.baseForm.contactUser = res.data.legalRepresentative;
                        this.baseForm.creditCode = res.data.creditCode;
                        this.baseForm.enterpriseName = res.data.enterpriseName;
                        this.baseForm.provinceName = "";
                        this.baseForm.cityName = "";
                        this.baseForm.provinceCode = "";
                        this.baseForm.cityCode = "";
                        this.baseForm.punishment = '0'
                    }
                });
                getNewProvince().then((res) => {
                    if (res.code == 200) {
                        this.provinceData = res.data
                        this.getProvinceT(Object.keys(res.data)[0],Object.values(res.data)[0])
                    }
                })
                this.getTechnology();
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            async firstNext() {
                this.baseForm.id = this.oldEvalId;
                for (let item in this.baseForm) {
                    if (item !== "detailAddress" || item !== "awards") {
                        if (this.baseForm[item] === "" && item != 'id') {
                            warnToast("请将数据填写完成");
                            return;
                        }
                    }
                }
                if (isNumber(this.baseForm.staffTotal) == false) {
                    warnToast("职工人数请输入数字")
                    return
                }
                ;
                if (isNumber(this.baseForm.researcherNumber) == false) {
                    warnToast("科技员工人数请输入数字")
                    return
                }
                ;
                let json = await confirmBase(this.baseForm);
                if (json.code == "200") {
                    this.evalId = json.data.evalId;
                    this.current = 1;
                    if (this.oldEvalId != "") {
                        getSecondInfo({
                            evalId: this.oldEvalId
                        }).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.ipList = res.data.ipList
                                    this.relatedNumber = res.data.relatedNumber
                                    this.threeYearIpTotal = res.data.threeYearIpTotal
                                    this.orgParticipation = res.data.orgParticipation + ""
                                }

                            }
                        })
                    }
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }


            },

            async secondNext() {
                let data = {
                    id: this.oldEvalId,
                    evalId: this.evalId,
                    relatedNumber: this.relatedNumber,
                    threeYearIpTotal: this.threeYearIpTotal,
                    orgParticipation: this.orgParticipation,
                    ipList: this.ipList
                };
                if (isNumber(this.relatedNumber) == false) {
                    warnToast("近三年知识产权总量（含已授权和申请中）该项请输入数字")
                    return ""
                }
                if (isNumber(this.threeYearIpTotal) == false) {
                    warnToast("与主要产品或服务相关的知识产权数量 该项请输入数字")
                    return ""
                }

                for (let i = 0; i < data.ipList.length; i++) {
                    if (isNumber(data.ipList[i].selfDevNumber) == false || isNumber(isNumber(data.ipList[i].traGiftMergerNumber) == false)) {
                        warnToast("请输入正整数")
                        return ''
                    }
                }
                let json = await confirmIp(data);

                if (json.code == "200") {
                    this.current = 2;
                    if (this.oldEvalId != "") {
                        getThirdInfo({evalId: this.oldEvalId}).then(res => {
                            if (res.code == 200) {
                                if (JSON.stringify(res.data) != '{}') {
                                    this.financeFrom = res.data
                                }

                            }
                        });
                    }

                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    warnToast(json.message)
                }


            },
            async confirmRdManageBt() {
                this.financeFrom.evalId = this.evalId;
                let json = await confirmFinance(this.financeFrom);
                if (json.code == "200") {
                    this.fourthForm.evalId = this.evalId;
                    let submitData = JSON.parse(JSON.stringify(this.fourthForm));
                    for (let item in submitData) {
                        if (!submitData[item]) {
                            warnToast("请将数据填写完整");
                            return;
                        }
                        if (typeof submitData[item] == 'object') {
                            submitData[item] = submitData[item].join(',')
                        }
                    }

                    submitData.evalId = this.evalId;
                    submitData["evalStatus"] = 1;
                    let json = await confirmRdManage(submitData);
                    if (json.code == "200") {
                        this.current = 3
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0;
                    } else {
                        errorToast(json.message)
                    }
                } else {
                    errorToast(json.message)
                }


            },

            async getTechnology() {
                let json = await getTechnologyField();
                if (json.code == "200") {
                    for (let i in json.data) {
                        let obj = {};
                        obj["name"] = json.data[i];
                        obj["value"] = i;
                        this.technologyList.push(obj);
                    }
                }
            },
            pre() {
                this.current -= 1;
            },
            getProvinceT(item,name) {
                this.baseForm.provinceName = this.provinceData[item]
                getByParentCode({parentCode: item}).then((res) => {
                    if (res.code == 200) {
                        this.cityData = res.data;
                        // console.log('this.cityData',this.cityData)
                        let data = Object.keys(this.provinceData).map(key => ({
                            value: key,
                            label: this.provinceData[key],
                            children: [],
                            loading: false
                        }))
                        // let data = [{
                        //     value: item,
                        //     label: name,
                        //     children: []
                        // }]
                        for(let key in this.cityData){
                            data[0].children.push({
                                value: key,
                                label: this.cityData[key]
                            })
                        }
                        console.log('data',data)
                        this.selectcitydata = data

                    }
                })
            },
            onLoadCity(item, cb) {
                item.loading = true
                getByParentCode({parentCode: item.value}).then((res) => {
                    for(let key in res.data){
                        item.children.push({
                            value: key,
                            label: res.data[key]
                        })
                    }
                    item.loading = false
                    cb()
                })
            },

            getCity(val) {
                console.log('changge-city',val)
                this.baseForm.provinceCode =val[0]
                this.baseForm.provinceName =this.provinceData[val[0]]
                this.baseForm.cityCode =val[1]
                this.baseForm.cityName =this.cityData[val[1]]
            },
            checkReport(){
                const {href} = this.$router.resolve({
                    path:'/report-result',
                    query:{
                        id:this.evalId
                    }
                })
                window.open(href,'_blank')
            }
        },
    };
</script>

<style scoped lang="scss">


    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    .table_div {
        height: 60px;
        line-height: 60px;
        display: flex;
        align-items: center;
    }
    .table_div:nth-child(2n+1){
        justify-content: flex-end;
    }
    .table_div:nth-child(2n){
        justify-content: flex-start;
    }


    .table_div2 {
        height: 60px;
        text-align: center;
        line-height: 60px;
        width: 100%;
    }

    /deep/ .ivu-table-cell {
        text-align: center !important;

        & > span {
            text-align: center !important;
        }
    }

    /deep/ .ivu-table th {
        height: 60px !important;
        text-align: center;
    }

    .ivu-table-row {
        height: 60px !important;
    }

    /deep/ .ivu-table td {
        height: 60px !important;
    }

    /deep/ .ivu-radio-inner:after {
        background-color: rgb(54, 194, 207);
    }

    .arrow_ld {
        color: white !important;
        display: inline-block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: rgb(54, 194, 207);
        line-height: 14px;
        text-align: center;
    }

    .div_first_td {
        background-color: rgb(54, 194, 207) !important;
        color: white;
    }

    .table-box {
        width: 100%;
        height: 55px;
        background-color: #f3fcfc;
        line-height: 55px;
        text-align: left;
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        width: 88px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #1890FF;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        margin: 32px auto 0;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        width: 90%;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        background-color: rgb(255, 255, 255);
        min-width: 900px;
        height: auto;
    }

    .right_content_parent {
        box-sizing: border-box;
        width: 80%;
        min-width: 960px;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        position: relative;
        margin-bottom: 130px;
    }

    .left_tab_parent {
        float: left;
        min-width: 240px;
        width: 20%;
        background-color: rgb(250, 250, 252);

        .left_tab {
            margin-top: 60px;

            div {
                width: 210px;
                margin: 0 auto;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                box-sizing: border-box;
                padding-left: 31px;
                margin-bottom: 20px;
                border-width: 1px;
                border-color: rgb(235, 235, 235);
                border-style: solid;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
            }
        }
    }

    /deep/ .demo-table-info-column {
        background-color: #f3fcfc;
        color: white;
    }

    /deep/ .ivu-table-tbody .demo-table-info-column {
        color: black !important;
    }

    /deep/ .ivu-steps .ivu-steps-head-inner{
        border-color: rgba(0, 0, 0, 0.15);
    }

    /deep/ .ivu-steps-item.ivu-steps-status-wait .ivu-steps-head-inner span{
        color: rgba(0, 0, 0, 0.25);
    }

    .hidden_span {
        width: 180px !important;
        display: inline-block;
        vertical-align: top;
        font-size: 14px;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 2;
        //-webkit-box-orient: vertical;
        //padding: 0 10px!important;
        //width: 200px!important;
        //overflow: hidden!important;
        //text-overflow: ellipsis!important;
        //white-space: nowrap!important;
        //display: inline-block;
    }

    .report_head {
        display: flex;
        justify-content: center;
        height: 70px;
        line-height: 70px;
        background-color: rgb(54, 194, 207);
        border: 1px solid rgb(54, 194, 207);
        color: white;
        font-size: 20px;
    }

    /deep/ .ivu-input{
        width: 360px;
        height: 32px!important;;
        background: #FFFFFF;
        border-radius: 4px!important;
        border: 1px solid rgba(0, 0, 0, 0.15);
        padding: 0 10px;
    }
    .input-two{
        width: 160px;
    }
    /deep/ .input-two > .ivu-input{
        width: 160px;
    }
    /deep/ .ivu-select-selection{
        border-radius: 4px!important;
        width: 360px!important;
    }
    .step2-item{
        height: 51px;
        text-align: center;
        line-height: 51px;
        background: #F6F9FA;
        color: #333;
        font-size: 15px;
    }
    .step2-notice{
        width: 444px;
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: #E6F7FF;
        border: 1px solid #91D5FF;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        margin: 25px auto 20px;
        border-radius: 4px;
    }
    .s-back{
        background: #fff;
    }
    .step2-input{
        width: 60px!important;
        height: 30px!important;
    }
    .step2-input /deep/ input{
        width: 60px!important;
        height: 30px!important;
        margin-right: 10px;
        border: none;
        border-bottom: 1px solid #ddd;
        background: transparent;
        border-radius: 0!important;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        text-align: center;
    }
    .step2-item-bg{
        background: #fff;
    }
    .step2-btn{
        width: 120px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        background: #52C41A;
        border-radius: 4px;
        color: #fff;
    }
    .step2-btn1{
        margin: 110px auto 0;
    }
    .step2-btn2{
        margin: 10px auto 0;
    }
    .step3-item{
        text-align: center;
        height: 51px;
        line-height: 51px;
        border: 1px solid #CCD5DB;
        font-size: 15px;
        color: #333;
    }
    .step3-item-bg{
        background: #F6F9FA;
    }
    .step3-input /deep/ input{
        width: 40px!important;
        height: 30px!important;
        margin-right: 10px;
        border: none;
        border-bottom: 1px solid #ddd;
        background: transparent;
        border-radius: 0!important;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        text-align: center;
    }
    .step3-item-checkbox{
        font-size: 12px;
        padding: 0 20px;
        width: 270px;
        line-height: 16px;
    }
    /deep/ .ivu-checkbox-checked .ivu-checkbox-inner{
        border-color:#1890FF!important;
        background: #1890FF!important;
    }
    /deep/ .ivu-radio-inner:after{
        border-color:#1890FF!important;
        background: #1890FF!important;
    }
    .step4-message-title{
        font-size: 24px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        margin: 18px 0 4px;
    }
    .step4-message-sub{
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.43);
    }
</style>
